<template>
  <v-container
    v-if="!isTotallyPaid && !isOrderEmpty && !isOrderOpenAndHasNoProductsYet"
    class="px-4"
    id="menu-order-details-payment-available-methods"
  >
    <available-methods />
  </v-container>
</template>

<script>
import AvailableMethods from "@/components/menu/order/payment/AvailableMethods";
import thankYouCommon from "@/mixins/firebase/thankYouCommon";

export default {
  name: "MenuOrderDetailsPaymentAvailableMethods",
  mixins: [thankYouCommon],
  provide: {
    heading: { align: "start" },
  },

  components: {
    AvailableMethods,
  },
};
</script>
