import { isTotallyPaidFromPlugin } from "../../plugins/isTotallyPaid";

export default {
  computed: {
    getOrderUuid() {
      return this.$store.getters["order/uuid"];
    },
    isOrderEmpty() {
      return this.$store.getters["order/status"] === `EMPTY`;
    },
    isOrderOpenAndHasNoProductsYet() {
      return (
        !this.$store.getters["order/extendedProducts"][0] &&
        this.$store.getters["order/status"] === `OPEN`
      );
    },
    isTotallyPaid() {
      return isTotallyPaidFromPlugin(
        this.$store.state.order.products,
        this.$store.state.order.currentTip.amount,
        this.$store.state.order.currentTip.status,
        this.$store.state.order.status
      );
      // const products = this.$store.state.order.products;
      // let accumulator = [];
      // products.forEach((product) => {
      //   let newObject = {
      //     sku: product.sku,
      //     isPaid:
      //       product.quantity === product.paidQuantity && product.quantity > 0,
      //   };
      //   accumulator.push(newObject);
      // });

      // if (this.$store.state.order.currentTip.amount > 0) {
      //   let tip = {
      //     sku: "tip",
      //     isPaid:
      //       this.$store.state.order.currentTip.status ===
      //       this.$store.getters["order/paymentStatus"][2],
      //   };
      //   accumulator.push(tip);
      // }

      // console.log("ORDER STATUS ->", this.$store.state.order.status);
      // if (this.$store.state.order.status === "EMPTY") {
      //   console.log("Return FALSE");
      //   return false;
      // }

      // if (this.$store.state.order.status === "TOTALLY_PAID") {
      //   console.log("Return TRUE");
      //   return true;
      // }

      // if (!accumulator[0]) {
      //   console.log("Return FALSE");
      //   return false;
      // }

      // console.log(`Return ${!accumulator.find((x) => x.isPaid === false)}`);
      // return !accumulator.find((x) => x.isPaid === false);
    },
  },
};
