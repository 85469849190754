export default {
  computed: {
    mobileBreakpoint() {
      return 768;
    },
    hasToShowMobileContent() {
      return this.isMobile();
    },
  },
  methods: {
    isMobile() {
      return this.$vuetify.breakpoint.width < this.mobileBreakpoint;
    },
  },
};
